<template>
  <div
    v-if="showCarousel"
    class="carousel"
  >
    <div class="carousel--Heading">
      <div class="carousel--HeadingTitle">
        <div class="carousel--Title">
          <slot name="title" />
        </div>
        <ha-button
          v-if="showMoreButton"
          class="carousel--ShowAllResults"
          variant="link"
          size="small"
          :data-testid="`Explore_LP_Carousel_ShowAllResults_Link`"
          @click="$emit('show-all')"
        >
          {{ $t('carousel.showAll') }} ({{ props.block?.result.nbHits }})
        </ha-button>
      </div>
      <div
        v-if="areGoButtonsDisplayed"
        class="carousel--Controls"
      >
        <button
          :class="['carousel--Control', { Disabled: !canGoPrevious }]"
          :aria-label="$t('carousel.actions.previousResults')"
          :tabindex="canGoPrevious ? 0 : -1"
          :data-testid="`Explore_LP_Carousel_ShowPreviousResults`"
          @click="showPrev()"
        >
          <icon-previous />
        </button>
        <button
          :class="['carousel--Control', { Disabled: !canGoNext }]"
          :aria-label="$t('carousel.actions.nextResults')"
          :tabindex="canGoNext ? 0 : -1"
          :data-testid="`Explore_LP_Carousel_ShowNextResults`"
          @click="showNext()"
        >
          <icon-next />
        </button>
      </div>
    </div>
    <skeleton
      v-if="isLoading"
      :index-name="props.block?.searchable?.indexName"
      :skeleton-number="skeletonNumber"
      is-carousel
    />
    <span
      v-else-if="!props.block?.result.hits?.length"
      class="carousel--NoResults"
    >
      {{ $t('widget.noResults') }}
    </span>
    <div
      v-else
      ref="wrapperRef"
      class="CarouselWrapper"
    >
      <div
        class="CarouselInner"
        :style="`transform: translateX(${translation}px)`"
      >
        <Hits
          :id="id"
          :hits="hitsSliced"
          :index-name="props.block?.searchable?.indexName"
          :item-number="itemsLength"
          :nb-hits="props.block.result?.nbHits"
          :mode="HitModes.CAROUSEL"
          @list-width="getListWidth"
          @show-all="$emit('show-all')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  nextTick
} from '#imports'
import { useStore } from 'vuex'
import { HaButton } from '@ha/components-v3'
import { functions } from '@ha/helpers'
import { HitModes, getMicroData } from '@/helpers'
import useConfigEnv from '@/composables/useConfigEnv'
import IconPrevious from '@/domains/search/components/Carousel/Partials/IconPrevious.vue'
import IconNext from '@/domains/search/components/Carousel/Partials/IconNext.vue'
import Skeleton from '@/components/Skeleton/Skeleton.vue'
import Hits from '@/components/Search/Hits.vue'
import { SEARCH_ALL_INDEX } from '~/domains/search/interfaces/search.interface'
import type { ContentBlock } from '~/domains/thematic/interfaces/content.interface'
const props = defineProps<{
  block: ContentBlock
  id?: string
  isLoading?: boolean
}>()

defineEmits(['show-all'])

const itemsLength = 12
const skeletonNumber = 4

const configEnv = useConfigEnv()
const store = useStore()
const wrapperRef = ref()
const page = ref(0)
const listWidth = ref(0)
const translation = ref(0)

const showCarousel = computed(
  () => props.block?.result.hits.length > 0
)

const hitsSliced = computed(() =>
  props.block?.result.hits.slice(0, itemsLength)
)

const showMoreButton = computed(
  () => props.block?.result.hits.length > itemsLength
)
const areGoButtonsDisplayed = computed(
  () =>
    props.block?.result.hits.length > skeletonNumber &&
    !props.isLoading
)

const canGoPrevious = computed(() => {
  return page.value > 0
})

const canGoNext = computed(() => {
  if (wrapperRef.value) {
    const width =
      wrapperRef.value?.clientWidth + wrapperRef.value?.offsetLeft
    return (
      page.value + 1 <
        props.block?.result.hits.length / skeletonNumber &&
      (listWidth.value > (translation.value - width) * -1 ||
        translation.value === 0 ||
        page.value < 3)
    )
  }
  return false
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResizeOverlapping)
})

const _handleResizeOverlapping = () => {
  /**
   * This method handle overlapping scenarios when the carousel is NOT :
   * - In its first slide (already handled by showPrev method).
   * - In its last slide (already handled by showNext method).
   * In other cases (ex : in a middle slide), on resize,
   * the overlap cause is mainly resizing, so we handle possible scenarios to
   * automatically reajust the carousel translation in both cases (left & right).
   */

  if (
    window.matchMedia('(min-width: 900px)') &&
    wrapperRef.value?.querySelectorAll
  ) {
    if (!wrapperRef.value) return

    const selector = wrapperRef.value?.querySelectorAll(`
      li:nth-of-type(${skeletonNumber * (page.value + 1) - 2}),
      li:nth-of-type(${skeletonNumber * (page.value + 1)})
    `)

    if (selector.firstItem && selector.lastItem) {
      const { left: wrapperLeft, right: wrapperRight } =
        wrapperRef.value.getBoundingClientRect()

      const { left: firstItemLeft, right: firstItemRight } =
        selector.firstItem.getBoundingClientRect()
      const { left: lastItemLeft, right: lastItemRight } =
        selector.lastItem.getBoundingClientRect()

      if (
        firstItemLeft < wrapperLeft &&
        wrapperLeft < firstItemRight
      ) {
        // Overlapping on left side
        translation.value += wrapperLeft - firstItemLeft
      } else if (
        lastItemLeft < wrapperRight &&
        lastItemRight > wrapperRight
      ) {
        // Overlapping on right side
        translation.value -= lastItemRight - wrapperRight
      }
    }
  }
}

/**
 * We debouncing this method to avoid multiples calls from left & right at same time due to CSS animation duration.
 * Using less than CSS animation duration bugging. Prefer to use the same delay as the delay of the CSS
 * animation duration when moving the carousel translation.
 */
const handleResizeOverlapping = functions.debounce(
  _handleResizeOverlapping
)

onMounted(() => {
  /**
   * We want dedicated @Event microdata, but only for Activity ones
   */
  if (
    props.block?.searchable.indexName === SEARCH_ALL_INDEX.ACTIVITIES
  ) {
    fetchMicrodata()
  }
  /**
   * Carousel controls have to be initialized on client-side
   * as it needs the window object to get some sizes
   */
  initControls()

  window.addEventListener('resize', handleResizeOverlapping)
})

const fetchMicrodata = () => {
  const json = []

  for (const hit of props.block.result.hits) {
    const microdata = getMicroData(hit, configEnv)
    if (microdata) {
      json.push(microdata)
    }
  }
  // Don't fetch an empty array
  if (json.length > 0) {
    // Max. 6 @Event microdata objects for each carousel
    store.dispatch('forms/fetchMicrodata', json.slice(0, 6))
  }
}

const initControls = () => {
  nextTick(() => {
    // wrapperRef.value = wrapperRef
  })
}

const showPrev = () => {
  if (canGoPrevious.value) {
    if (!wrapperRef.value) return
    const { width } = wrapperRef.value.getBoundingClientRect()
    // to get the responsive gutter value stored in a CSS variable
    // we have to find it with getPropertyValue()
    // then convert it using the base font-size in rem units
    const gutter = parseFloat(
      getComputedStyle(document.body)
        .getPropertyValue('--gutter')
        .replace('rem', '')
    )
    const gutterBase = parseInt(
      getComputedStyle(document.documentElement).fontSize,
      10
    )
    const offset = gutter * gutterBase
    const toTranslate = width + offset
    // translation with calculated offset
    page.value -= 1

    if (page.value === 0) {
      // If the first carousel item is overlapping, we handle it to stick it to the start of current slide.
      translation.value = 0
    } else {
      translation.value += toTranslate
    }
  }
}

const showNext = () => {
  if (canGoNext.value) {
    if (!wrapperRef.value) return
    // find wrapperRef width
    const { width: wrapperWidth } =
      wrapperRef.value.getBoundingClientRect()
    // to get the responsive gutter value stored in a CSS variable
    // we have to find it with getPropertyValue()
    // then convert it using the base font-size in rem units
    const gutter = parseFloat(
      getComputedStyle(document.body)
        .getPropertyValue('--gutter')
        .replace('rem', '')
    )
    const gutterBase = parseInt(
      getComputedStyle(document.documentElement).fontSize,
      10
    )
    const offset = gutter * gutterBase
    // translation with calculated offset
    const toTranslate = wrapperWidth + offset

    page.value += 1

    const {
      left: lastCarouselItemLeft,
      right: lastCarouselItemRight
    } = wrapperRef.value
      .querySelector('li:last-of-type')
      .getBoundingClientRect()

    if (
      toTranslate >
      lastCarouselItemLeft - wrapperRef.value?.offsetWidth
    ) {
      // If the last carousel item is overlapping, we handle it to stick it to the end of current slide.
      translation.value -=
        wrapperWidth -
        offset +
        lastCarouselItemRight -
        wrapperRef.value?.offsetWidth * 2
    } else {
      translation.value -= toTranslate
    }
  }
}

const getListWidth = (value) => {
  listWidth.value = value
}
</script>

<style lang="scss">
.carousel {
  + .carousel {
    margin-top: calc(var(--gutter) * 2);
  }

  &Wrapper {
    overflow: hidden;
  }

  &Inner {
    transition: transform 0.5s;
  }

  &--Heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: $ha-spacing-medium;

    @include mediaQuery(900) {
      margin-bottom: $ha-spacing-large;
    }

    &Title {
      display: inline-flex;
      flex: 1 1 auto;
      align-items: flex-end;
      justify-content: space-between;

      @include mediaQuery(900) {
        align-items: baseline;
        justify-content: flex-start;
      }

      .HaButton {
        font-size: $ha-font-size-tiny;
        text-decoration: none;
        color: var(--hads-color-iris) !important;

        @include mediaQuery(900) {
          font-size: $ha-font-size-small;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &--Title {
    padding-right: $ha-spacing-large;
    line-height: $ha-line-height-small;

    > * {
      margin-bottom: 0;
      font-size: $ha-font-size-large;

      @include mediaQuery(600) {
        font-size: $ha-font-size-big;
      }

      @include mediaQuery(900) {
        font-size: $ha-font-size-jumbo;
      }

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  &--ShowAllResults {
    text-decoration: underline !important;
    flex: 0 0 auto;
    margin-right: var(--gutter);

    @include mediaQuery(900) {
      margin-right: 0;
    }
  }

  &--Controls {
    display: flex;
    align-self: center;
    gap: $ha-unit;

    @include mediaQuery(900, 'max') {
      display: none;
    }
  }

  &--Control {
    border-radius: $ha-radius-regular;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $ha-unit * 2;
    outline: none;

    @include transition-base((box-shadow, background, stroke));

    &:hover {
      cursor: pointer;
    }

    &::before {
      content: '';
      height: 145%;
      aspect-ratio: 1;
      position: absolute;
      border-radius: $ha-unit * 0.5;
    }

    &:hover::before,
    &:focus::before {
      background: set-alpha('primary', 0.25);
      box-shadow: 0 0 0 ($ha-unit * 0.25) set-alpha('primary', 0.5);
    }

    &.Disabled {
      pointer-events: none;

      &::before {
        background: var(--ha-color-background);
      }

      // Avoid height issue on map mulitple-asso carousel.
      $chevron-disabled-color: var(--ha-color-text-lightest);

      > svg {
        // Avoid height issue on map mulitple-asso carousel.
        stroke: $chevron-disabled-color;
      }

      .HaButton--Icon {
        // Avoid height issue on map mulitple-asso carousel.
        color: $chevron-disabled-color;
      }
    }

    > svg {
      // Avoid height issue on map mulitple-asso carousel.
      height: inherit;
    }

    > svg,
    .HaButton--Icon {
      // Avoid height issue on map mulitple-asso carousel.
      z-index: 2;
    }

    svg {
      fill: none;
      stroke: var(--ha-color-text-light);
      stroke-width: 3;

      @include transition-base((stroke));
    }
  }

  .Hits,
  .Skeleton {
    grid-auto-flow: column;

    @include mediaQuery(900) {
      column-gap: 0;
    }

    &-Organization {
      grid-auto-columns: $ha-unit * 32; // 256px

      @include mediaQuery(900) {
        grid-auto-columns: calc(33.33% - calc(var(--gutter) / 3));
      }
    }

    &-Activity {
      grid-auto-columns: $ha-unit * 30; // 240px

      @include mediaQuery(900) {
        grid-auto-columns: calc(33.33% - calc(var(--gutter) / 3));
      }
    }

    &-Project {
      grid-auto-columns: $ha-unit * 45; // 360px

      @include mediaQuery(900) {
        grid-auto-columns: calc(50% - calc(var(--gutter) / 2));
      }
    }

    &-Category {
      grid-auto-columns: $ha-unit * 20; // 160px

      @include mediaQuery(900) {
        grid-auto-columns: calc(25% - calc(var(--gutter) / 4));
      }
    }

    > * {
      margin-left: var(--gutter);

      &:last-of-type {
        // prevent double margin shrinking
        width: calc(100% + var(--gutter));
        padding-right: var(--gutter);

        @include mediaQuery(900) {
          width: calc((100% - var(--gutter)) - 1);
          padding-right: 0;
        }
      }
    }
  }

  /**
   * Animations
   */

  .slide-left-leave-active,
  .slide-left-enter-active,
  .slide-right-leave-active,
  .slide-right-enter-active {
    transition: transform 0.5s $ha-transition-timing;
  }

  .slide-left-enter,
  .slide-right-leave-to {
    transform: translate3d(100%, 0, 0);
  }

  .slide-right-enter,
  .slide-left-leave-to {
    transform: translate3d(-100%, 0, 0);
  }

  /**
  * Touch screens
  */

  @media (hover: none) and (pointer: coarse) {
    &--Controls {
      display: none;
    }

    .HorizontalScroll {
      overflow: auto hidden;
    }
  }

  /**
  * Normal screens
  */

  @media (hover: hover) and (pointer: fine) {
    ul.Hits-carousel {
      overflow: visible;
    }

    @media (max-width: ($ha-breakpoint-large - 1)) {
      &Inner {
        width: fit-content;
      }
    }
  }

  &--NoResults {
    display: flex;
    justify-content: center;
    padding: var(--gutter);
    color: var(--ha-color-text);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-small;
    background-color: var(--ha-color-background);
    border-radius: $ha-radius-large;

    @include mediaQuery(900) {
      font-size: var(--ha-font-size-regular);
    }
  }
}
</style>
